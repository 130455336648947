<template>
  <div class="container-box">
    <div class="main-box">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item @click="$router.go(-1)"
          ><router-link to="/">首页</router-link></a-breadcrumb-item
        >
        <a-breadcrumb-item style="color: #15b7dd">资讯速递</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="content-box">
        <div class="title-box">
          <div class="line"></div>
          资讯速递
        </div>

        <div class="tab-box">
          <div class="tab-left"></div>
          <div class="swiper" ref="tabWidth">
            <template>
              <swiper
                ref="mySwipert"
                :options="swiperOptiont"
                v-if="tabList.length > 0"
              >
                <swiper-slide v-for="(item, index) in tabList" :key="index">
                  <div class="industryitem">
                    <div class="tab-list">
                      <div
                        class="tab-title"
                        :class="{ 'tab-active': item.isClick }"
                      >
                        {{ item.columnTitle }}
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <div
                  v-show="showTabWidth"
                  class="
                    swiper-button-next swiper_btn swiper-button-white
                    tab-next
                  "
                  slot="button-next"
                  @mouseover="changeMaskTab(1)"
                  @mouseout="changeMaskTab(2)"
                >
                  <img
                    v-show="swiperRightTab"
                    src="@/assets/image/index/icon_next.png"
                    alt=""
                  />
                  <img
                    v-show="!swiperRightTab"
                    src="@/assets/image/index/icon_next1.png"
                    alt=""
                  />
                </div>
              </swiper>
            </template>
          </div>
        </div>

        <div class="list-box">
          <div v-for="(item, index) in infoList" :key="index" class="info-list">
            <div class="info-right">
              <div
                class="info-banner"
                v-if="item.pcImg"
                @click="toDetail(item.id)"
              >
                <img class="info-img" :src="item.pcImg" alt="" />
              </div>
              <div class="info-detail">
                <div class="detail-title" @click="toDetail(item.id)">
                  {{ item.articleTitle }}
                </div>
                <div class="more-box">
                  <!-- <div class="detail-more" @click="toDetail(item.id)">
                    查看详情>
                  </div> -->
                  <div class="detail-source">
                    <div class="views">发布： {{ item.publishTime }}</div>
                    <div class="views">浏览量：{{ item.pageviews }}</div>
                    <div class="source">
                      来源：<span class="blue">{{ item.source }}</span>
                    </div>
                  </div>
                </div>
                <div class="detail-content" @click="toDetail(item.id)">
                  {{ item.overview }}
                </div>
              </div>
            </div>
          </div>
          <img
            v-if="show"
            class="more-icon"
            src="@/assets/image/index/icon_more.png"
            alt=""
            @click="getInfo()"
          />
          <a-spin class="spin" v-show="loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      loading: false,
      show: true,
      typeID: "",
      currentNum: 1,
      tabList: [],
      infoList: [],

      tabShow: "",
      showTabWidth: false,
      swiperRightTab: false,
      swiperOptiont: {
        slidesPerView: "auto",
        spaceBetween: 20,
        navigation: {
          nextEl: ".tab-next",
          disabledClass: "my-button-disabled",
        },
        on: {
          click: () => {
            this.onTab();
          },
        },
      },
    };
  },
  // 事件处理器
  methods: {
    onTab() {
      if (this.$refs.mySwipert.$swiper) {
        let realIndex = this.$refs.mySwipert.$swiper.clickedIndex;
        if (!isNaN(realIndex)) {
          this.tabList.forEach((item, index) => {
            if (index == realIndex) {
            console.log(item);
              item.isClick = true;
              this.typeID = item.columnCode ;
            } else {
              item.isClick = false;
            }
          });
        }
      }
    },

    toDetail(id) {
      this.$router.push(
        "/infoDetail?newsId=" + this.$AES.encode_data(String(id)) + 
        '&banner=1'
      );
    },

    changeMaskTab(e) {
      if (e == 1) {
        this.swiperRightTab = true;
      } else if (e == 2) {
        this.swiperRightTab = false;
      }
    },

    getInfotab() {
      this.$ajax({
        url: "/hxclass-pc/ArticleColumn/list/News",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tabList = res.data.map((item, index) => {
            if (index == 0) {
              this.typeID = item.columnCode;
              return { ...item, isClick: true };
            } else {
              return { ...item, isClick: false };
            }
          });

          let widthTotal = 0;
          setTimeout(() => {
            if (this.$refs.infoSwipert) {
              this.$refs.mySwipert.$children.forEach((element) => {
                widthTotal = widthTotal + element.$el.clientWidth + 20;
              });
              this.tabShow = widthTotal;
              if (this.tabShow > this.$refs.tabWidth.offsetWidth) {
                this.showTabWidth = true;
              }
            }
          }, 500);
        }
      });
    },

    getInfo() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-pc/Article/client/list",
        params: {
          columnCode: this.typeID,
          pageNum: this.currentNum,
          pageSize: 10,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.infoList = this.infoList.concat(res.data.records);
          this.currentNum++;
          if (this.infoList.length == res.data.total) {
            this.show = false;
          } else {
            this.show = true;
          }
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getInfotab();
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$emit("setIndex", false);
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    typeID(newval) {
      this.typeID = newval;
      this.currentNum = 1;
      this.infoList = [];
      this.getInfo();
    },
  },
};
</script>

<style lang="less" scoped>
.spin {
  position: absolute;
  top: 200%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container-box {
  .main-box {
    padding-top: 22px;
    padding-bottom: 60px;
    .content-box {
      margin-top: 22px;
      width: 100%;
      background-color: #fff;
      .title-box {
        display: flex;
        align-items: center;
        padding: 40px 40px 0;
        font-size: 24px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #15b7dd;
        line-height: 28px;
        .line {
          display: inline-block;
          margin-right: 8px;
          width: 4px;
          height: 19px;
          background: #15b7dd;
          border-radius: 2px 2px 2px 2px;
          // vertical-align: middle;
        }
      }

      .tab-box {
        position: relative;
        margin-top: 24px;
        padding: 0 0 0 20px;
        height: 38px;
        overflow: hidden;
        .tab-left {
          position: absolute;
          left: 0;
          height: 100%;
          width: 20px;
          background: rgba(21, 183, 221, 0.1);
        }
        .tab-list {
          margin: 0 20px;
          .tab-title {
            cursor: pointer;
            min-width: 104px;
            height: 100%;
            font-size: 20px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #666666;
            line-height: 38px;
            text-align: center;
          }
          .tab-active {
            color: #fff;
            font-weight: 500;
            background: #15b7dd;
          }
        }
        // ::v-deep.ant-carousel {
        //   .slick-slide {
        //     text-align: center;
        //     height: 447px;
        //     line-height: 160px;
        //     background: #364d79;
        //     overflow: hidden;
        //   }
        //   .slick-slide h3 {
        //     color: #fff;
        //   }
        // }
        // ::v-deep.dotsClass {
        //   li {
        //     margin: 0 10px;
        //     button {
        //       width: 24px !important;
        //     }
        //   }
        // }
        // // 轮播样式
        // .industryitem {
        //   position: relative;
        //   z-index: 3;
        // }
        // ::v-deep.swiper-button-next {
        //   position: absolute;
        //   right: -15px;
        //   top: 200px;
        //   img {
        //     width: 26px;
        //     height: 44px;
        //     position: relative;
        //     border-bottom-left-radius: 22px;
        //     border-top-left-radius: 22px;
        //   }
        // }
        // .swiper {
        //   position: relative;
        // }

        ::v-deep.ant-carousel {
          .slick-slide {
            text-align: center;
            height: 447px;
            line-height: 160px;
            background: #364d79;
            overflow: hidden;
          }
          .slick-slide h3 {
            color: #fff;
          }
        }
        ::v-deep.dotsClass {
          li {
            margin: 0 10px;
            button {
              width: 24px !important;
            }
          }
        }
        // 轮播样式
        .industryitem {
          position: relative;
          z-index: 3;
        }
        // /deep/.swiper-container {
        //   overflow: inherit;
        // }
        /deep/.swiper-button-next:after,
        .swiper-container-rtl .swiper-button-prev:after {
          content: "";
        }
        ::v-deep.swiper-button-next {
          position: absolute;
          right: -2px;
          top: 22px;
          z-index: 5;
          height: 38px;
          width: 24px;
          background: #ebf6fb;
          img {
            position: relative;
            width: 7px;
            height: 14px;
          }
        }
        /deep/.swiper-slide {
          width: auto;
        }
        /deep/.tab-active {
          padding: 0 32px;
        }
        .swiper {
          position: relative;
          width: auto;
          display: inline-block;
          background: rgba(21, 183, 221, 0.1);
          max-width: 100%;
        }
      }

      .list-box {
        position: relative;
        display: flex;
        flex-direction: column;
        // align-items: center;
        padding: 5px 30px 31px;
        .info-list {
          transition: all .4s ease;
          display: flex;
          padding: 30px 20px;
          border-bottom: 1px solid #f2f2f2;
          .info-left {
            display: flex;
            align-items: center;
            margin-right: 49px;
            .info-data {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 29px;
              .info-day {
                font-size: 50px;
                font-family: PingFangMedium;
                font-weight: bold;
                color: @border-color;
                line-height: 59px;
                letter-spacing: 5px;
              }
              .info-year {
                font-size: 14px;
                font-family: PingFangMedium;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
                line-height: 16px;
                letter-spacing: 1px;
              }
            }
            .line-first {
              margin-right: 30px;
              width: 4px;
              height: 61px;
              background: @border-color;
            }
            .info-line {
              width: 30px;
              height: 3px;
              background: rgba(0, 0, 0, 0.2);
            }
          }

          .info-right {
            display: flex;
            width: 1044px;
            // min-height: 115px;
            .info-banner {
              margin-right: 34px;
              width: 152px;
              min-width: 152px;
              height: 115px;
              box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
              border-radius: 5px;
              overflow: hidden;
              .info-img {
                width: 152px;
                min-width: 152px;
                height: 115px;
                &:hover {
                  transition: all 1s;
                  -webkit-transform: scale(1.2);
                }
              }
            }
            .info-detail {
              display: flex;
              flex-direction: column;
              // justify-content: space-between;
              width: 100%;
              .detail-title {
                cursor: pointer;
                font-size: 18px;
                font-family: PingFangMedium;
                font-weight: 400;
                color: #333333;
                line-height: 27px;
                // letter-spacing: 1px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                &:hover {
                  color: @theme;
                }
              }
              .detail-content {
                cursor: pointer;
                width: 796px;
                font-size: 14px;
                font-family: PingFangMedium;
                font-weight: 400;
                color: #333333;
                line-height: 21px;
                // letter-spacing: 1px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                -webkit-line-clamp: 2;
                margin-top: 10px;
                &:hover {
                  // color: @theme;
                }
              }
              .more-box {
                display: flex;
                width: 100%;
                margin-top: 12px;
                .detail-source {
                  display: flex;
                  font-size: 12px;
                  font-family: PingFangMedium;
                  font-weight: 400;
                  color: #666666;
                  line-height: 18px;
                  // letter-spacing: 1px;
                  .views {
                    margin-right: 51px;
                  }
                  .source {
                    .blue {
                      // color: @border-color;
                    }
                  }
                }
                .detail-more {
                  cursor: pointer;
                  font-size: 14px;
                  font-family: PingFangMedium;
                  font-weight: 400;
                  color: #333333;
                  line-height: 16px;
                  letter-spacing: 1px;
                }
              }
            }
          }
          &:hover{
            transform: translateY(-2px);
            box-shadow: 2px 2px 10px 0px #cecece;
            .info-right .info-detail .detail-title{
              color: @theme;
            }
          }
        }
        .more-icon {
          margin-top: 40px;
          width: 127px;
          height: 36px;
        }
      }
    }
  }
}

// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .container-box .main-box .content-box {
    .list-box .info-list .info-right .info-detail .detail-title {
      font-weight: bold;
    }
    .tab-box .tab-list .tab-title {
      font-weight: bold;
    }
  }
}
</style>
